import themeConfig from '@configs/themeConfig' 

// ** Database Local 
import { Database } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs' 
import logger from '@nozbe/watermelondb/utils/common/logger'

import { mySchema } from './schema' 
import { SysParameter, DataLookup, Translation, Company, Branch, message, users, conversation} from './model'  

logger.silence();

const adapter = new LokiJSAdapter({
  dbName: themeConfig.app.appId,
  schema: mySchema,
  useWebWorker: false,
  useIncrementalIndexedDB: true
})

const database = new Database({
  adapter,
  modelClasses: [SysParameter, DataLookup, Translation, Company, Branch, message, users, conversation ],
  actionsEnabled: true,
})
 
export default database