// **  Initial State
const initialState = {
  userData: {}, 
  company: {label:'PT. Sejahtera Buana Trada'},
  branchs: [],
  connection: null,
  isConnected: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data
      }
    case 'LOGOUT':
      return { ...state, userData: {}, branchs:[], company:'', connection: null, isConnected:false}
    case 'CHANGE_COMPANY': 
      return { ...state, company: action.data }
    case 'SET_BRANCHS': 
      return { ...state, branchs: action.data }
    case 'SET_CHATCHANNEL': 
        return { ...state, connection: action.data }
    case 'SET_CONNECTED': 
        return { ...state, isConnected: action.data }
    default:
      return state
  }
}

export default authReducer
