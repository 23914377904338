// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import chat from './chat'
import layout from './layout' 

const rootReducer = combineReducers({
  auth, 
  navbar,
  layout,
  chat
})

export default rootReducer
