import { appSchema, tableSchema } from '@nozbe/watermelondb'
// Data types: >> Columns have one of three types: string, number, or boolean. 
export const mySchema = appSchema({
  version: 14,
  tables: [
    tableSchema({
      name: 'sysparameters',
      columns: [
        { name: 'name', type: 'string', isIndexed: true },
        { name: 'value', type: 'string' }
      ]
    }),
    tableSchema({
      name: 'datalookups',
      columns: [
        { name: 'groupid', type: 'string' },
        { name: 'code', type: 'string' },
        { name: 'caption', type: 'string' } , 
        { name: 'parentid', type: 'string', isOptional: true }, 
        { name: 'seq', type: 'number', isOptional: true } ,
        { name: 'isactive', type: 'boolean' }  
      ],
    }),
    tableSchema({
      name: 'translation',
      columns: [
        { name: 'name', type: 'string' },
        { name: 'ind', type: 'string', isOptional: true },
        { name: 'eng', type: 'string', isOptional: true }, 
        { name: 'jpn', type: 'string', isOptional: true }, 
        { name: 'lang1', type: 'string', isOptional: true },
        { name: 'lang2', type: 'string', isOptional: true } 
      ],
    }),
    tableSchema({
      name: 'company',
      columns: [
        { name: 'companyid', type: 'string' },
        { name: 'companycode', type: 'string' },
        { name: 'companyname', type: 'string' }
      ],
    }),
    tableSchema({
      name: 'branch',
      columns: [
        { name: 'companyid', type: 'string'  },
        { name: 'branchcode', type: 'string' },
        { name: 'companyname', type: 'string'}
      ],
    }),
    tableSchema({
      name: 'message' ,
      columns: [
        { name:'MID' , type:'number' }, 
        { name:'CID' , type:'number' }, 
        { name:'Type' , type:'number' }, 
        { name:'Message' , type:'string', isOptional: true }, 
        { name:'Attachment' , type:'string', isOptional: true }, 
        { name:'Filename' , type:'string', isOptional: true }, 
        { name:'ImageUrl' , type:'string', isOptional: true }, 
        { name:'CreatedBy' , type:'number' }, 
        { name:'CreatedDate' , type:'string' }  
      ],
    }),
    tableSchema({
      name: 'users' ,
      columns: [
        { name:'UID' , type:'number'}, 
        { name:'UserName' , type:'string' }, 
        { name:'DisplayName' , type:'string', isOptional: true }, 
        { name:'Avatar' , type:'string', isOptional: true }, 
        { name:'UserType' , type:'string', isOptional: true }, 
        { name:'Overview' , type:'string', isOptional: true }, 
        { name:'Company' , type:'string', isOptional: true }, 
        { name:'Position' , type:'string', isOptional: true }, 
        { name:'Connection' , type:'string', isOptional: true }, 
        { name:'Status' , type:'number' }, 
        { name:'LastUpdate' , type:'string' }, 
      ],
    }),
    tableSchema({
      name: 'conversation' ,
      columns: [
        { name:'CID' , type:'number'}, 
        { name:'Title' , type:'string', isOptional: true }, 
        { name:'Description' , type:'string', isOptional: true }, 
        { name:'Logo' , type:'string', isOptional: true }, 
        { name:'Type' , type:'number' }, 
        { name:'Member' , type:'number' }, 
        { name:'MsgCount' , type:'number' }, 
        { name:'User1' , type:'number', isOptional: true }, 
        { name:'User2' , type:'number', isOptional: true }, 
        { name:'CreatedBy' , type:'number', isOptional: true }, 
        { name:'CreatedDate' , type:'string', isOptional: true }, 
        { name:'LastUpdate' , type:'string', isOptional: true }, 
        { name:'LastMessage' , type:'string', isOptional: true }, 
      ],
    }) 
  ],
})
