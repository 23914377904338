import { Model, Q } from '@nozbe/watermelondb'
import { field, children, lazy, action } from '@nozbe/watermelondb/decorators'
 
export  class DataLookup extends Model {
  static table = 'datalookups' 

    @field('groupid')
    GroupID

    @field('code')
    Code 

    @field('caption')
    Caption  

    @field('parentid')
    ParentID  

    @field('seq')
    Seq 

    @field('isactive')
    IsActive  
 
}


export  class SysParameter extends Model {
  static table = 'sysparameters' 

  @field('name')
  Name

  @field('value')
  Value
 
} 


export  class Translation extends Model {
  static table = 'translation' 

  @field('name')
  name

  @field('ind')
  ind
 
  @field('eng')
  eng

  @field('jpn')
  jpn

  @field('lang1')
  lang1

  @field('lang2')
  lang2

}

export  class Company extends Model {
  static table = 'company' 

  @field('companyid')
  CID

  @field('companycode')
  Code
 
  @field('companyname')
  Name 

}

export  class Branch extends Model {
  static table = 'branch' 

  @field('companyid')
  CID

  @field('branchcode')
  Code
 
  @field('companyname')
  Name 

}

export  class users extends Model {
	static table = 'users' 
	
	@field('UID')
  UID

  @field('UserName')
  UserName

  @field('DisplayName')
  DisplayName

  @field('Avatar')
  Avatar

  @field('UserType')
  UserType

  @field('Overview')
  Overview

  @field('Company')
  Company

  @field('Position')
  Position 

  @field('Status')
  Status

  @field('LastUpdate')
  LastUpdate

}

export  class message extends Model {
	static table = 'message' 
	
	@field('MID')
  MID

  @field('CID')
  CID

  @field('Type')
  Type

  @field('Message')
  Message

  @field('Attachment')
  Attachment

  @field('Filename')
  Filename

  @field('ImageUrl')
  ImageUrl

  @field('CreatedBy')
  CreatedBy

  @field('CreatedDate')
  CreatedDate

}

export  class conversation extends Model {
	  static table = 'conversation' 
	
    @field('CID')
    CID

    @field('Title')
    Title

    @field('Description')
    Description

    @field('Logo')
    Logo

    @field('Type')
    Type

    @field('Member')
    Member

    @field('MsgCount')
    MsgCount

    @field('User1')
    User1

    @field('User2')
    User2

    @field('CreatedBy')
    CreatedBy

    @field('CreatedDate')
    CreatedDate

    @field('LastUpdate')
    LastUpdate
    
    @field('LastMessage')
    LastMessage

}
